import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import mainBlockBkgIMG from '../img/main-block-bkg.png';
import service1IMG from '../img/service-1.png';
import service2IMG from '../img/service-2.png';
import service3IMG from '../img/service-3.png';
import service4IMG from '../img/service-4.png';
import service5IMG from '../img/service-5.png';
import service6IMG from '../img/service-6.png';
import service7IMG from '../img/service-7.png';
import aboutIndex1IMG from '../img/about-index-1.png';
import rewardIMG from '../img/reward.png';
import workExample1IMG from '../img/work-example-1.png';
import workExample2IMG from '../img/work-example-2.png';
import workExample3IMG from '../img/work-example-3.png';
import workExample4IMG from '../img/work-example-4.png';
import workExample5IMG from '../img/work-example-5.png';
import partner1IMG from '../img/partner-1.png';
import partner2IMG from '../img/partner-2.png';
import partner3IMG from '../img/partner-3.png';
import partner4IMG from '../img/partner-4.png';
import partner5IMG from '../img/partner-5.png';
import partner6IMG from '../img/partner-6.png';
import mapIMG from '../img/map.png';
